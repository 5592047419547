<template>
	<div :id="powerBIContainerId" class="power-bi-container"></div>
</template>

<script>
import * as pbi from "powerbi-client";
import { v4 as uuidv4 } from "uuid";

export default {
	props: {
		data: {
			type: Object,
			default: null
		},
		suffixContainerKey: {
			type: [String, Number],
			default: uuidv4()
		}
	},
	computed: {
		powerBIContainerId() {
			return `power-bi-container-${this.data?.data?.id}-${this.suffixContainerKey}`;
		}
	},
	mounted() {
		const permissions = pbi.models.Permissions.All;
		const configPowerBI = {
			type: "report",
			tokenType: pbi.models.TokenType.Embed,
			accessToken: this.data?.data?.token,
			embedUrl: this.data?.data?.embedUrl,
			id: this.data?.data?.id,
			permissions
		};
		const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
		const dashboardContainer = document.getElementById(this.powerBIContainerId);
		const dashboard = powerbi.embed(dashboardContainer, configPowerBI);
		dashboard.off("loaded");
		dashboard.off("rendered");
		dashboard.on("error", () => {
			this.dashboard.off("error");
		});
	}
};
</script>

<style lang="scss" scoped>
	.power-bi-container {
		// stylelint-disable-next-line property-no-unknown
		aspect-ratio: 16/9;

		::v-deep iframe {
			border: 0;
		}
	}
</style>