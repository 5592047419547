
/**
 * Enum for graph type.
 * @readonly
 * @enum {EnumGraphType}
 */
export const GRAPH_TYPE = Object.freeze({
	GRAFANA: "grafana",
	POWER_BI: "powerBI"
});
