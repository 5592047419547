import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules/dashboard/modules";
import viewDevices from "./modules/dashboard/viewDevices";
import devices from "./modules/dashboard/devices";
import tankInfo from "./modules/tanks/info";
import tripInfo from "./modules/tanks/tripInfo";
import tankLevel from "./modules/tanks/tankLevel";
import tankGasConsumption from "./modules/tanks/tankGasConsumption";
import tankGasConsumptionRate from "./modules/tanks/tankGasConsumptionRate";
import tankMonthly from "./modules/tanks/tankMonthly";
import tankHistory from "./modules/tanks/history";
import tankConsumptionHistory from "./modules/tanks/tankConsumptionHistory";
import meterInfo from "./modules/meters/info";
import meterConsumption from "./modules/meters/meterConsumption";
import meterGasFlowRate from "./modules/meters/meterGasFlowRate";
import meterOrderHistory from "./modules/meters/meterOrderHistory";
import meterApprovals from "./modules/meters/meterApprovals";
import recycleWaterFlowrate from "./modules/zlds/recycleWaterFlowrate";
import outletRecycleWater from "./modules/zlds/outletRecycleWater";
import electricalConductivity from "./modules/zlds/electricalConductivity";
import waterFlow from "./modules/cers/waterFlow";
import staff from "./modules/staff";
import insertStaff from "./modules/staff/insertStaff";
import devicesNoSignal from "./modules/deviceHealth/devicesNoSignal";
import devicesInactive from "./modules/deviceHealth/devicesInactive";
import shipTos from "./modules/shipTos";
import zldInfo from "./modules/zlds/info";
import powerBI from "./modules/powerBI";
import zldLastMonth from "./modules/zlds/lastMonth";
import cerInfo from "./modules/cers/info";
import cerHistory from "./modules/cers/history";
import moneySaving from "./modules/cers/moneySaving";
import toast from "./modules/toast";
import error from "./modules/error";
// overall
import moduleDevices from "./modules/overall/moduleDevices";
import productType from "./modules/overall/productType";
import userRoles from "./modules/overall/userRoles";
import userUsages from "./modules/overall/userUsages";
import overallMonitor from "./modules/overall";

import pagePermission from "./modules/pagePermission";
import user from "./modules/user";
import sidebar from "./modules/sidebar";

import customer from "./modules/customer";
import customerManagement from "./modules/customer/customerManagement";
import devicePermissionLists from "./modules/customer/devicePermissionLists";
import customerRolePermission from "./modules/customer/customerRolePermission";

import activityLog from "./modules/activityLog";
import activityLogAdd from "./modules/activityLog/add";

import noSignalConfig from "./modules/noSignalConfig";
import userMonitor from "./modules/userMonitor";
import userMonitorOverall from "./modules/userMonitor/overall";
import userMonitorMostActive from "./modules/userMonitor/mostActive";
import userLogins from "./modules/userMonitor/userLogins";
import userTypes from "./modules/userMonitor/userTypes";
import nitroFASInfo from "./modules/nitroFAS/info";
import nitroFASProduction from "./modules/nitroFAS/productionLineChart";
import nitroFASFlowRate from "./modules/nitroFAS/nitroGenFlowRate";
import nitroFASOrderHistory from "./modules/nitroFAS/nitroFASHistory";
import nitroFASNitrogenConsumption from "./modules/nitroFAS/nitrogenConsumption";
import nitroFasDrossGernation from "./modules/nitroFAS/nitroFasDrossGeneration";
import nitroFasSolderConsumption from "./modules/nitroFAS/nitroFasSolderConsumption";
import nitroFasTotalFinishedGood from "./modules/nitroFAS/totalFinishedGood";
import nitroFasDrossTotalFinishedGood from "./modules/nitroFAS/drossTotalFinishedGood";
import nitroFasNitrogenTotalFinishedGood from "./modules/nitroFAS/nitrogenTotalFinishedGood";

import o2AerationInfo from "./modules/o2Aeration/info";
import o2AerationOxygenDissolve from "./modules/o2Aeration/oxygenDissolve";
import o2AerationOxygenConsumption from "./modules/o2Aeration/oxygemConsumption";
import o2AerationPowerConsumption from "./modules/o2Aeration/powerConsumption";
import o2AerationLastMonth from "./modules/o2Aeration/lastMonth";
import o2AerationHistory from "./modules/o2Aeration/history";
import o2AerationOxygenFlowRate from "./modules/o2Aeration/oxygenFlowRate";


import zldVinyThaiWaterFlowRate from "./modules/zldVinyThai/waterFlowRate";
import zldVinyThaiConsumption from "./modules/zldVinyThai/zldVinyThaiConsumption";

import silicaInReCycleWater from "./modules/zldVinyThai/silicaInRecycleWater";
import electricalRecycleWater from "./modules/zldVinyThai/electricalRecycleWater";
import zldOrderHistory from "./modules/zldVinyThai/zldOrderHistory";
import electricalInletWater from "./modules/zldVinyThai/electricalInletWater";
import recycleWaterProduction from "./modules/zldVinyThai/recycleWaterProduction";
import genGasInfo from "./modules/genGas/info";
import genGasFlowRate from "./modules/genGas/genGasFlowRate";
import genGasHistory from "./modules/genGas/history";
import genGasConsumption from "./modules/genGas/genGasConsumption";
import shiptoStatus from "./modules/shiptoStatus";
import standardModule from "./modules/standardModule";
import standardModuleCreate from "./modules/standardModule/create";
import standardModuleEdit from "./modules/standardModule/edit";
import standardModulePage from "./modules/standardModule/page";
import standardModuleHistory from "./modules/standardModule/history";
import soldToManagement from "./modules/soldToManagement";
import powerInfo from "./modules/power/info";
import powerTrend from "./modules/power/trend";
import powerPeakDemand from "./modules/power/peakDemand";
import powerVoltage from "./modules/power/voltage";
import powerCurrent from "./modules/power/current";
import powerHistory from "./modules/power/history";
import powerConsumption from "./modules/power/consumption";
import powerKwh from "./modules/power/kwh";
import sorInfo from "./modules/sor/info";
import sorTrend from "./modules/sor/trend";
import sorVoltage from "./modules/sor/voltage";
import sorCurrent from "./modules/sor/current";
import sorGeneration from "./modules/sor/generation";
import sorHistory from "./modules/sor/history";
import sorKwh from "./modules/sor/kwh";

import lngInfo from "./modules/lng/info";
import lngTripInfo from "./modules/lng/tripInfo";
import lngLevel from "./modules/lng/lngLevel";
import lngMonthly from "./modules/lng/lngMonthly";
import lngHistory from "./modules/lng/history";

import flowDiagramList from "./modules/flowDiagram/list";
import flowDiagramSingle from "./modules/flowDiagram/single";
import flowDiagramCreate from "./modules/flowDiagram/create";
import flowDiagramUpdate from "./modules/flowDiagram/update";
import flowDiagramDelete from "./modules/flowDiagram/delete";
import flowDiagramShipTo from "./modules/flowDiagram/shipTo";
import flowDiagramDeviceList from "./modules/flowDiagram/deviceList";

import notiGroupConfig from "./modules/notificationGroup/config";

import maintenanceStatus from "./modules/maintenanceStatus";

// Bulk Hydrogens
import bhyInfo from "./modules/bulkHydrogen/info";
import bhyLevel from "./modules/bulkHydrogen/level";
import bhyOrderHistory from "./modules/bulkHydrogen/orderHistories";
import bhyTripInfo from "./modules/bulkHydrogen/tripInfo";
import bhyDeliverie from "./modules/bulkHydrogen/deliverie";

// E-Ordering
import eOrderingList from "./modules/eOrdering/orderList";
import eOrderingHistories from "./modules/eOrdering/history";
import eOrderingInfo from "./modules/eOrdering/Info";
import eOrderingTripInfo from "./modules/eOrdering/tripInfos";
import eOrderingCreate from "./modules/eOrdering/create";
import eOrderingPendingOrderList from "./modules/eOrdering/pendingOrderList";

// Furnace
import furnaceInfo from "./modules/furnace/info";
import furnaceTemperature from "./modules/furnace/temperature";
import furnaceHistories from "./modules/furnace/histories";
import furnaceConsumption from "./modules/furnace/consumption";
import furnacePressure from "./modules/furnace/pressure";
import furnaceNaturalGas from "./modules/furnace/naturalGas";
import furnaceReports from "./modules/furnace/reports";
import furnaceGlassLevel from "./modules/furnace/glassLevel";

// Air comp
import airCompInfo from "./modules/airComp/info";
import airCompPressure from "./modules/airComp/pressure";
import airCompSEC from "./modules/airComp/sec";
import airCompPower from "./modules/airComp/power";
import airCompFlowRate from "./modules/airComp/airflowrate";
import airCompPowerCost from "./modules/airComp/powerCost";
import airCompHistories from "./modules/airComp/histories";
import airCompOverallEnergy from "./modules/airComp/overallenergy";
import airCompOverallAirVolume from "./modules/airComp/overallairvolume";
import airCompEnergySaving from "./modules/airComp/energySaving";

// Grafana Management
import grafanaManagement from "./modules/grafanaManagement/";

// Big Store Management
import bigStoreManagement from "./modules/bigStoreManagement/";
import bannerManagement from "./modules/bigStoreManagement/bannerManagement";
import ourServiceManagement from "./modules/bigStoreManagement/ourServiceManagement";
import ourDevicesManagement from "./modules/bigStoreManagement/ourDevicesManagement";

// Devices With Customers
import devicesWithCustomers from "./modules/devicesWithCustomers";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		modules,
		viewDevices,
		devices,
		devicesWithCustomers,
		tankInfo,
		tankLevel,
		tankGasConsumption,
		tankGasConsumptionRate,
		tankMonthly,
		tankHistory,
		tankConsumptionHistory,
		meterInfo,
		meterConsumption,
		shipTos,
		zldInfo,
		powerBI,
		zldLastMonth,
		cerInfo,
		meterGasFlowRate,
		meterOrderHistory,
		recycleWaterFlowrate,
		outletRecycleWater,
		electricalConductivity,
		waterFlow,
		moneySaving,
		pagePermission,
		user,
		sidebar,
		staff,
		cerHistory,
		insertStaff,
		toast,
		error,
		tripInfo,
		customer,
		customerManagement,
		devicePermissionLists,
		customerRolePermission,
		activityLog,
		activityLogAdd,
		devicesNoSignal,
		devicesInactive,
		moduleDevices,
		noSignalConfig,
		productType,
		userRoles,
		userUsages,
		userMonitor,
		userMonitorOverall,
		userMonitorMostActive,
		userLogins,
		overallMonitor,
		userTypes,
		meterApprovals,
		nitroFASInfo,
		nitroFASProduction,
		nitroFASFlowRate,
		nitroFASOrderHistory,
		nitroFASNitrogenConsumption,
		o2AerationInfo,
		o2AerationOxygenDissolve,
		o2AerationOxygenConsumption,
		o2AerationPowerConsumption,
		o2AerationLastMonth,
		o2AerationHistory,
		o2AerationOxygenFlowRate,
		zldVinyThaiWaterFlowRate,
		silicaInReCycleWater,
		electricalRecycleWater,
		recycleWaterProduction,
		zldOrderHistory,
		electricalInletWater,
		zldVinyThaiConsumption,
		genGasInfo,
		genGasFlowRate,
		genGasHistory,
		genGasConsumption,
		shiptoStatus,
		standardModule,
		standardModuleCreate,
		standardModuleEdit,
		standardModulePage,
		standardModuleHistory,
		soldToManagement,
		nitroFasDrossGernation,
		nitroFasSolderConsumption,
		nitroFasTotalFinishedGood,
		nitroFasDrossTotalFinishedGood,
		nitroFasNitrogenTotalFinishedGood,
		powerInfo,
		powerTrend,
		powerPeakDemand,
		powerVoltage,
		powerCurrent,
		powerHistory,
		powerConsumption,
		powerKwh,
		sorInfo,
		sorTrend,
		sorVoltage,
		sorCurrent,
		sorGeneration,
		sorHistory,
		sorKwh,
		lngInfo,
		lngTripInfo,
		lngLevel,
		lngMonthly,
		lngHistory,
		flowDiagramList,
		flowDiagramSingle,
		flowDiagramCreate,
		flowDiagramUpdate,
		flowDiagramDelete,
		flowDiagramShipTo,
		flowDiagramDeviceList,
		notiGroupConfig,
		maintenanceStatus,
		bhyInfo,
		bhyLevel,
		bhyOrderHistory,
		bhyTripInfo,
		bhyDeliverie,
		eOrderingList,
		eOrderingHistories,
		eOrderingInfo,
		eOrderingTripInfo,
		eOrderingCreate,
		eOrderingPendingOrderList,
		furnaceInfo,
		furnaceTemperature,
		furnaceHistories,
		furnaceConsumption,
		furnacePressure,
		furnaceNaturalGas,
		furnaceReports,
		furnaceGlassLevel,
		airCompInfo,
		airCompPressure,
		airCompSEC,
		airCompPower,
		airCompFlowRate,
		airCompPowerCost,
		airCompHistories,
		airCompOverallEnergy,
		airCompOverallAirVolume,
		airCompEnergySaving,
		grafanaManagement,
		bannerManagement,
		ourDevicesManagement,
		bigStoreManagement,
		ourServiceManagement
	}
});
