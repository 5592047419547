<template>
	<BaseLayout pageTitle="Device Permission">
		<div class="main-content">
			<div class="row">
				<SearchFilterForm
					:query="query"
					@submit="handleSubmitFilter"
				/>
			</div>
			<div class="row container-list">
				<DeviceList
					:loading="isLoading"
					:items="data"
				/>
				<BasePagination
					v-if="data.length > 0"
					:value="pagination.currentPage"
					:totalPage="pagination.lastPage"
					class="module-pagination"
					@onPageChange="handlePageChange"
				/>
			</div>
		</div>
	</BaseLayout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import SearchFilterForm from "@/components/DevicesWithCustomers/SearchFilterForm.vue";
import DeviceList from "@/components/DevicesWithCustomers/DeviceList.vue";
import { cleanParams } from "../../selectors/helpers/";

export default {
	name: "DevicesWithCustomers",

	components: {
		SearchFilterForm,
		DeviceList
	},

	data() {
		return {
			query: {
				page: this.$route.query.page || null,
				keyword: this.$route.query.keyword || null
			}
		};
	},

	computed: {
		...mapState("devicesWithCustomers", {
			isLoading: "isLoading",
			data: "data",
			pagination: "pagination"
		})
	},

	created() {
		this.fetchData();
		this.addActivityLogDevicePermission();
	},

	methods: {
		...mapActions("toast", {
			showToast: "show"
		}),
		...mapActions("devicesWithCustomers", {
			getDevicesWithCustomers: "getDevicesWithCustomers"
		}),
		...mapActions("activityLogAdd", {
			addActivityLogDevicePermission: "addActivityLogDevicePermission"
		}),
		updateUrl() {
			const query = cleanParams(JSON.parse(JSON.stringify(this.query)));
			this.$router.push({ query }).catch(() => {});
		},
		async fetchData() {
			this.updateUrl();
			return this.getDevicesWithCustomers(this.query);
		},
		handleSubmitFilter(form = {}) {
			this.query.keyword = form.keyword;
			this.query.page = 1;
			this.fetchData();
		},
		handlePageChange(pageNum = 1) {
			this.query.page = pageNum;
			this.fetchData();
		}
	}
};
</script>

<style lang="scss" scoped>
.container-list {
	padding-left: $padding-base;
	padding-right: $padding-base;

	.module-pagination {
		display: flex;
		justify-content: flex-end;
	}
}
</style>
