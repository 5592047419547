import { getDevicesWithCustomerAPI } from "@/services/api/devices.api";
import { DEVICES_WITH_CUSTOMERS_REQUEST, DEVICES_WITH_CUSTOMERS_SUCCESS, DEVICES_WITH_CUSTOMERS_FAILURE } from "../../types";
import { transformShipToStatuses } from "../../../selectors/transform/shiptoStatus/transformShipToStatus";

export default {
	namespaced: true,

	state: {
		data: transformShipToStatuses([]),
		isLoading: false,
		pagination: {
			currentPage: 1,
			lastPage: 1,
			total: 0
		}
	},

	mutations: {
		[DEVICES_WITH_CUSTOMERS_REQUEST](state) {
			state.isLoading = true;
			state.data = [];
		},
		[DEVICES_WITH_CUSTOMERS_SUCCESS](state, { data, pagination }) {
			state.data = data;
			state.pagination = pagination;
			state.isLoading = false;
		},
		[DEVICES_WITH_CUSTOMERS_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
		}
	},

	actions: {
		async getDevicesWithCustomers({ commit }, params = {}) {
			try {
				commit(DEVICES_WITH_CUSTOMERS_REQUEST);
				const { data } = await getDevicesWithCustomerAPI(params);
				commit(DEVICES_WITH_CUSTOMERS_SUCCESS, data);
			} catch (error) {
				commit(DEVICES_WITH_CUSTOMERS_FAILURE);
			}
		}
	}
};
