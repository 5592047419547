<template>
	<div :class="['module-item', { active: isActive }]">
		<div class="module-item-header" @click="toggle">
			<h2 class="module-item-name">{{ item.name }}</h2>
			<div class="module-item-code"><span class="module-item-label">Ship-to code</span> {{ item.code }}</div>
			<div class="module-item-key"><span class="module-item-label">Location key</span> {{ item.locationKey || '-' }}</div>
			<div class="module-item-toggle">
				<a>
					{{ isActive ? 'Hide device' : 'Show device' }} <FontAwesomeIcon :icon="['far', 'chevron-circle-down']" />
				</a>
			</div>
		</div>
		<div class="module-item-body container">
			<div class="row container-item">
				<div class="container-item-head">
					<div class="container-item-head-status">
						<span class="label">
							CRM Status
						</span>
						<span class="value">
							{{ item.isActive ? "Active" : "Inactive" }}
						</span>
					</div>
					<div class="container-item-head-action">
						<BaseSwitch
							:checked="status.parent"
							@input="handleParentStatusChange($event, item.id)"
						/>
					</div>
				</div>
				<div class="container-item-body">
					<BaseTableCustom
						:tableOptions="tableOptions"
						:loading="false"
					>
						<template v-slot:thead>
							<tr>
								<th
									v-for="({ value, ...rest }, index) in table.thead"
									:key="index"
									:class="rest.class"
								>
									{{ value }}
								</th>
							</tr>
						</template>
						<template v-slot:tbody>
							<tr
								v-for="(item, index) in table.tbody"
								:key="index"
							>
								<td class="device-permission-icon">
									<router-link :to="`/device-permission?page=1&keyword=${item.shipId}`"><FontAwesomeIcon :icon="['far', 'users']" /></router-link> {{ item.shipId }}
								</td>
								<td>
									{{ item.productName }}
								</td>
								<td>
									{{ item.module }}
								</td>
								<td>
									{{ item.isActive ? 'Active' : 'Inactive' }}
								</td>
								<td class="grafana">
									<span @click="handleRouteToGrafanaManagement(item.id)"><PenToSquareIcon></PenToSquareIcon></span>
								</td>
								<td>
									<div>
										<BaseButton
											v-show="item.collapsePowerBI"
											width="170px" size="small"
											type="primary" outline
											@click="handleCollapse(item.collapsePowerBI, item.index)">
											Add Workspace & Report ID
										</BaseButton>
									</div>
									<div class="power-bi-box" v-show="!item.collapsePowerBI">
										<BaseFormInput
											v-model="item.workspaceId"
											label="Workspace ID"
											:inputOptions="['small']"
											:controlOptions="['input-powerbi']"
											:maxLength="36"
											:isErrorPowerBI="item.isWorkspaceIdInvalid"
											placeholder="Workspace ID"
											errorMessagePowerBI="The workspace ID must be 36 characters."
										/>
										<BaseFormInput
											v-model="item.reportId"
											label="Report ID"
											:inputOptions="['small']"
											:controlOptions="['input-powerbi']"
											:maxLength="36"
											:isErrorPowerBI="item.isReportIdInvalid"
											placeholder="Report ID"
											errorMessagePowerBI="The report ID must be 36 characters."

										/>
										<BaseButton
											class="margin-button"
											width="100px"
											size="small"
											type="primary"
											outline
											@click="handleChildPowerBISave(item.workspaceId, item.reportId, item.id, item.index)">
											Save ID
										</BaseButton>
									</div>
								</td>
								<td class="action">
									<BaseSwitch
										:name="`child-device-${index}`"
										:checked="status[`child-device-${index}`]"
										:disabled="switchChildDisabled"
										@input="handleChildStatusChange($event, `child-device-${index}`, item.id, item.index)"
									/>
								</td>
							</tr>
						</template>
					</BaseTableCustom>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BaseTableCustom from "@/components/BaseTableCustom.vue";
import BaseSwitch from "@/components/BaseSwitch.vue";
import BaseFormInput from "@/components/BaseFormInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import PenToSquareIcon from "@/components/icon/PenToSquareIcon.vue";
import { cloneDeep } from "../../selectors/helpers";
import { PAGE_NAME } from "../../enums/pagePermission";

export default {
	name: "ShipToListItem",

	components: {
		BaseTableCustom,
		BaseSwitch,
		BaseFormInput,
		BaseButton,
		PenToSquareIcon
	},

	props: {
		item: {
			type: Object,
			default: null
		},
		query: {
			type: Object,
			default: () => ({})
		}
	},

	data() {
		return {
			tableOptions: [
				"is-striped is-fullwidth"
			],
			isActive: false,
			status: {
				parent: false
			},
			devices: []
		};
	},

	computed: {
		table() {
			return {
				thead: [
					{ value: "Ship ID", key: "", class: "" },
					{ value: "Product", key: "", class: "" },
					{ value: "Module", key: "", class: "" },
					{ value: "CRM Status", key: "", class: "" },
					{ value: "Grafana", key: "", class: "" },
					{ value: "PowerBI Report", key: "", class: "" },
					{ value: "Visibility", key: "", class: "action" }
				],
				tbody: this.devices
			};
		},
		switchChildDisabled() {
			return !this.status.parent;
		}
	},

	created() {
		this.devices = this.item.devices.map((device, index) => {
			return { ...device,
				collapsePowerBI: true,
				index,
				isWorkspaceIdInvalid: false,
				isReportIdInvalid: false };
		});
		// set swtich status
		this.status.parent = cloneDeep(this.item.isVisible);
		// eslint-disable-next-line guard-for-in
		for (const deviceIndex in this.item.devices) {
			const device = this.item.devices[deviceIndex];
			this.$set(this.status, `child-device-${deviceIndex}`, device.isVisible);
		}
	},

	methods: {
		async toggle() {
			this.isActive = !this.isActive;
		},
		handleParentStatusChange(checked = false, id = "") {
			this.status.parent = checked;
			const type = "ShipTo";
			const isVisible = checked;
			this.$emit("statusChange", { type, id, isVisible });
		},
		handleChildStatusChange(checked = false, name = "", id = "", index) {
			this.status[name] = checked;
			const type = "Device";
			const isVisible = checked;
			this.devices[index].isVisible = !this.devices[index].isVisible;
			this.$emit("statusChange", { type, id, isVisible });
		},
		handleChildPowerBISave(workspaceId = "", reportId = "", id = "", index) {
			const regex = /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/;
			// This is regex check format GUID
			// ^ represents the starting of the string.
			// [{]? represents the ‘{‘ character that is optional.
			// [0-9a-fA-F]{8} represents the 8 characters from a-f, A-F, and 0-9.
			// – represents the hyphens.
			// ([0-9a-fA-F]{4}-){3} represents the 4 characters from a-f, A-F, and 0-9 that is repeated 3 times separated by a hyphen (-).
			// [0-9a-fA-F]{12} represents the 12 characters from a-f, A-F, and 0-9.
			// [}]? represents the ‘}’ character that is optional.
			// $ represents the ending of the string.
			let isWorkspaceIdInvalid = !regex.test(workspaceId);
			let isReportIdInvalid = !regex.test(reportId);
			if (workspaceId === "" && reportId === "") {
				isWorkspaceIdInvalid = false;
				isReportIdInvalid = false;
			}
			const isVisible = this.devices[index].isVisible;
			this.devices[index].isWorkspaceIdInvalid = isWorkspaceIdInvalid;
			this.devices[index].isReportIdInvalid = isReportIdInvalid;
			if (!isWorkspaceIdInvalid && !isReportIdInvalid) {
				const type = "Device";
				this.$emit("powerBISave", { type, id, workspaceId, reportId, isVisible });
				this.devices[index].collapsePowerBI = true;
			}
		},
		handleCollapse(isCollapse, index) {
			this.devices[index].collapsePowerBI = !isCollapse;
		},
		handleRouteToGrafanaManagement(id) {
			this.$router.push({
				name: PAGE_NAME.GRAFANA_MANAGEMENT,
				query: {
					id
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.module-item {
	border-radius: 4px;
	border: 1px solid $color-silver;
	background-color: $color-grey-1;
	margin-bottom: rem(16);
	line-height: 1.5;
	overflow: hidden;

	// .module-item-name
	&-name {
		flex-basis: rem(380);
		margin-right: rem(30);
		font-size: $font-24;
		line-height: 1.2;
		color: $color-dark-blue-grey;
	}

	// .module-item-label
	&-label {
		font-size: $font-18;
		font-weight: 400;
		color: $color-gunmetal-two;
		margin-right: rem(8);
	}

	// .module-item-code, .module-item-key
	&-code,
	&-key {
		font-weight: 600;
		color: $color-dark-blue-grey;
		margin-right: rem(64);

		@include desktop {
			min-width: rem(130);
			margin-right: rem(10);
		}
	}

	// .module-item-toggle
	&-toggle {
		margin-left: auto;

		a {
			width: rem(120);
			display: inline-block;
			color: $color-cerulean;
			font-weight: 600;

			svg {
				color: $color-cerulean;
				font-size: $font-16;
				margin-left: rem(10);
				position: relative;
				top: rem(3);
			}
		}
	}

	// .module-item-header
	&-header {
		display: flex;
		width: 100%;
		padding: rem(20) rem(16);
		cursor: pointer;
	}

	// .module-item-body
	&-body {
		display: none;
		width: 100%;
		background-color: $color-white;
		padding: rem(16) rem(16) 0 rem(16);
	}

	.fa-chevron-circle-down {
		transition: 0.3s transform ease-in-out;
	}

	&.active {
		.module-item-body {
			display: block;
		}

		.fa-chevron-circle-down {
			transform: rotate(180deg);
		}
	}
}

.container-item {
	padding: 0 $spacing-5 $spacing-5 $spacing-5;

	&-head {
		@include fontSub();

		display: flex;
		justify-content: space-between;

		&-status {
			.label {
				color: $color-grey-4;
				font-size: $font-20;
				margin-right: 8px;
			}

			.value {
				color: $color-dark-blue-grey;
				font-size: $font-20;
				font-weight: $font-weight-bold;
			}
		}

		&-action {
			display: flex;
			align-items: center;
			margin-right: rem(14);
		}
	}

	&-body {
		margin-top: rem(14);

		/* stylelint-disable */
		&::v-deep .container-custom-main-table .action {
			text-align: right !important;
		}
		/* stylelint-enable */
	}
}

.power-bi-box {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: rem(10);
}

.margin-button {
	margin-top: rem(10);
}

$max-width-power-bi-box: rem(1534);

@media screen and (max-width: $max-width-power-bi-box) {
	.power-bi-box {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: rem(10);
	}
}

.grafana {
	span {
		cursor: pointer;
	}

	svg {
		margin-top: 2px;
		fill: $color-azure;
	}
}

.device-permission-icon {
	svg {
		width: rem(14);
		height: rem(16);
	}
}
</style>
