<template>
	<BaseLoading isFullPage />
</template>

<script>
import BaseLoading from "@/components/BaseLoading.vue";
import { mapActions } from "vuex";
import { PAGE_NAME } from "../enums/pagePermission";
import { getAzureAccessTokenAPI } from "../services/api/user.api";
import { TOAST_TYPES } from "../enums/toast";

export default {
	name: "LoginCallback",
	components: {
		BaseLoading
	},
	methods: {
		...mapActions("user", {
			getStaffSessionAPI: "getStaffSessionAPI"
		}),

		...mapActions({
			showToast: "toast/show"
		})
	},
	async created() {
		const isRequiredLogin = this.$route?.query?.login_required;

		if (isRequiredLogin) {
			this.$router.push({
				name: PAGE_NAME.LOGIN_PAGE
			});
		}

		const authorizeCode = this.$route?.query?.code;

		const state = JSON.parse(decodeURIComponent(this.$route?.query?.state));
		// get and delete for one-time use
		const codeVerifier = localStorage.getItem("code_verifier");
		localStorage.removeItem("code_verifier");

		if (authorizeCode) {
			try {
				const reponse = await getAzureAccessTokenAPI(authorizeCode, codeVerifier);
				await this.getStaffSessionAPI(reponse?.data?.access_token);
				this.$router.replace({
					name: state.route,
					params: {
						...state.params
					},
					query: {
						...state.query
					}
				});
			} catch (error) {
				const status = error?.response?.status;
				const email = error?.response?.data?.email;

				switch (status) {
					case 401:
						this.$router.replace({
							name: "permission",
							query: {
								azureSignIn: true,
								email
							}
						});
						break;
					default:
						this.showToast({
							type: TOAST_TYPES.RESPONSE_ERROR,
							props: { title: "Unable to login", message: error.response?.data?.error_description, delay: 6000 }
						});
						setTimeout(() => {
							this.$router.push({
								name: PAGE_NAME.LOGIN_PAGE
							});
						}, 6000);
						break;
				}
			}
		}
	}
};
</script>
