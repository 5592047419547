import dayjs from "dayjs";
import { COMPANY_LIST_REQUEST, COMPANY_LIST_SUCCESS, COMPANY_LIST_FAILURE, CUSTOMER_CREATE_REQUEST, CUSTOMER_CREATE_SUCCESS, CUSTOMER_CREATE_FAILURE, CUSTOMER_CREATE_CLEAR, CUSTOMER_DETAIL_REQUEST, CUSTOMER_DETAIL_SUCCESS, CUSTOMER_DETAIL_FAILURE, CUSTOMER_UPDATE_FORM_REQUEST, CUSTOMER_UPDATE_FORM_SUCCESS, CUSTOMER_UPDATE_FORM_FAILURE, CUSTOMER_UPDATE_PASSWORD_REQUEST, CUSTOMER_UPDATE_PASSWORD_SUCCESS, CUSTOMER_UPDATE_PASSWORD_FAILURE, CUSTOMER_CLEAR_COMPANY, POST_CUSTOMER_PERMISSION_REQUEST, POST_CUSTOMER_PERMISSION_SUCCESS, POST_CUSTOMER_PERMISSION_FAILURE } from "../../../store/types";
import { getCompaniesAPI } from "../../../services/api/companies";
import { postCustomersAPI, getCustomerByIdAPI, putCustomerUpdateAPI, putCustomerPasswordUpdateAPI, postCustomerPermissionAPI } from "../../../services/api/customerManageApi";
import { parseCustomerListToOptions } from "../../../services/api/transforms/parseCompany";
import { trasnformSoldToOptions } from "../../../selectors/transform/devicePermission";

export default {
	namespaced: true,

	state: {
		data: [],
		companies: [],
		isLoading: false,
		isEmpty: false,
		pagination: {
			currentPage: 1,
			lastPage: 1,
			total: 0
		},
		errors: {
			email: ""
		},
		customerCreateSuccess: false,
		customerUpdateSuccess: false,
		customerUpdatePassowordSuccess: false,
		customerProfile: {
			isCompanyExist: false
		},
		permissions: {
			views: {
				soldTos: [
				],
				shipTos: [
				],
				applications: [
				],
				devices: [
				]
			},
			autoUpdate: {
				soldTos: false,
				shipTos: false,
				devices: false
			},
			all: {
				soldTos: false,
				shipTos: false,
				applications: false,
				devices: false
			}
		}
	},
	mutations: {
		// request handle
		[COMPANY_LIST_REQUEST](state) {
			state.isLoading = true;
		},

		[CUSTOMER_CREATE_REQUEST](state) {
			state.isLoading = true;
		},

		[CUSTOMER_DETAIL_REQUEST](state) {
			state.isLoading = true;
		},

		[CUSTOMER_UPDATE_PASSWORD_REQUEST](state) {
			state.isLoading = true;
		},

		[CUSTOMER_UPDATE_FORM_REQUEST](state) {
			state.isLoading = true;
		},

		[CUSTOMER_UPDATE_PASSWORD_SUCCESS](state, data) {
			state.customerUpdatePassowordSuccess = true;
			state.isLoading = false;
			state.data = data;
		},

		[CUSTOMER_UPDATE_FORM_SUCCESS](state, data) {
			state.isLoading = false;
			state.data = data;
			state.customerUpdateSuccess = true;
		},

		[COMPANY_LIST_SUCCESS](state, response) {
			state.isLoading = false;
			state.companies = parseCustomerListToOptions(response);
		},

		[CUSTOMER_CREATE_SUCCESS](state, data) {
			state.data = data;
			state.isLoading = false;
			state.customerCreateSuccess = true;
		},

		[CUSTOMER_DETAIL_SUCCESS](state, data) {
			state.isLoading = false;
			const { permissions, ...customerProfile } = data;
			state.customerProfile = customerProfile;
			state.permissions = permissions;
		},

		[CUSTOMER_CLEAR_COMPANY](state) {
			state.companies = [];
		},

		[CUSTOMER_CREATE_CLEAR](state) {
			state.errors = {};
			state.customerCreateSuccess = false;
			state.customerUpdateSuccess = false;
		},

		// failure handler

		[CUSTOMER_CREATE_FAILURE](state, errors) {
			state.data = [];
			state.companies = [];
			state.isLoading = [];
			state.isEmpty = [];
			state.pagination = {
				currentPage: 1,
				lastPage: 1,
				total: 0
			};

			state.errors = errors;
		},

		[CUSTOMER_DETAIL_FAILURE](state, errors) {
			state.data = [];
			state.companies = [];
			state.isLoading = [];
			state.isEmpty = [];
			state.pagination = {
				currentPage: 1,
				lastPage: 1,
				total: 0
			};

			state.errors = errors;
		},

		[CUSTOMER_UPDATE_FORM_FAILURE](state, errors) {
			state.data = [];
			state.companies = [];
			state.isLoading = [];
			state.isEmpty = [];
			state.pagination = {
				currentPage: 1,
				lastPage: 1,
				total: 0
			};
			state.errors = errors;
		},

		[COMPANY_LIST_FAILURE](state) {
			state.data = [];
			state.companies = [];
			state.isLoading = [];
			state.isEmpty = [];
			state.pagination = {
				currentPage: 1,
				lastPage: 1,
				total: 0
			};
			state.errors = {
				email: ""
			};
		},
		[POST_CUSTOMER_PERMISSION_REQUEST](state) {
			state.isLoading = true;
		},
		[POST_CUSTOMER_PERMISSION_SUCCESS](state, data) {
			state.permissions = data.permission;
		},
		[POST_CUSTOMER_PERMISSION_FAILURE](state) {
			state.isLoading = false;
		}
	},
	getters: {
		getCreateAt(state) {
			return dayjs(state.customerProfile.createdAt).format("D MMM YYYY, HH:mm");
		},
		getLastLoggedInAt(state) {
			return state.customerProfile.lastLoggedInAt === "-" ? state.customerProfile.lastLoggedInAt : dayjs(state.customerProfile.lastLoggedInAt).format("D MMM YYYY, HH:mm");
		},
		getLastUpdatePassword(state) {
			return dayjs(state.customerProfile.passwordUpdatedAt).format("MMM DD, YYYY");
		},
		getCustomerSoldToValues(state) {
			return trasnformSoldToOptions(state.permissions.views.soldTos);
		},
		getCustomerShipToValues(state) {
			return state.permissions.views.shipTos;
		},
		getCustomerApplication(state) {
			return state.permissions.views.applications || [];
		},
		getCustomerApplicationValues(state) {
			return state.permissions.views.applications.reduce((acc, next) => {
				return [
					...acc,
					{
						code: next,
						name: next.toUpperCase(),
						key: next.toUpperCase()
					}
				];
			}, []);
		},
		getCustomerDeviceValues(state) {
			return state.permissions.views.devices.reduce((acc, { id, productId, shipId, app, shipToId, ...props }) => {
				const newValue = {
					id,
					code: id,
					name: `${productId} - ${shipId}`,
					key: `${productId} - ${shipId}`,
					shipToId,
					app,
					...props
				};
				return [
					...acc,
					newValue
				];
			}, []);
		},
		getIsUpdateShipTo(state) {
			return state.permissions.autoUpdate.shipTos;
		},
		getIsUpdateDeivce(state) {
			return state.permissions.autoUpdate.devices;
		},
		isAllSoldTo(state) {
			return state.permissions.all.soldTos;
		},
		isAllShipTo(state) {
			return state.permissions.all.shipTos;
		},
		isAllApplication(state) {
			return state.permissions.all.applications;
		},
		isAllDevice(state) {
			return state.permissions.all.devices;
		}
	},
	actions: {
		async fetchCompaniesLists({ commit }, params = {}) {
			try {
				commit(COMPANY_LIST_REQUEST);
				const { data } = await getCompaniesAPI(params);
				commit(COMPANY_LIST_SUCCESS, data);
			} catch (e) {
				commit(COMPANY_LIST_FAILURE);
			}
		},

		async sendCreateCustomer({ commit }, payload) {
			try {
				commit(CUSTOMER_CREATE_REQUEST);
				const { data } = await postCustomersAPI(payload);
				commit(CUSTOMER_CREATE_SUCCESS, data);
			} catch (e) {
				const { errors } = e.response.data;
				commit(CUSTOMER_CREATE_FAILURE, errors);
			}
		},

		customersManageClearErrorsField({ commit }) {
			commit(CUSTOMER_CREATE_CLEAR);
		},

		async fetchCustomerById({ commit }, id) {
			try {
				commit(CUSTOMER_DETAIL_REQUEST);
				const { data } = await getCustomerByIdAPI(id);
				commit(CUSTOMER_DETAIL_SUCCESS, data);
			} catch (e) {
				commit(CUSTOMER_DETAIL_FAILURE);
			}
		},

		async updateCustomerProfile({ commit }, { id, customerPayload }) {
			try {
				commit(CUSTOMER_UPDATE_FORM_REQUEST);
				const { data } = await putCustomerUpdateAPI(id, customerPayload);
				commit(CUSTOMER_UPDATE_FORM_SUCCESS, data);
			} catch (e) {
				const { errors } = e.response.data;
				commit(CUSTOMER_UPDATE_FORM_FAILURE, errors);
			}
		},

		async updateCustomerPassword({ commit }, { id, customerPasswordPayload }) {
			try {
				commit(CUSTOMER_UPDATE_PASSWORD_REQUEST);
				const { data } = await putCustomerPasswordUpdateAPI(id, customerPasswordPayload);
				commit(CUSTOMER_UPDATE_PASSWORD_SUCCESS, data);
			} catch (e) {
				commit(CUSTOMER_UPDATE_PASSWORD_FAILURE);
			}
		},
		clearCompanyLists({ commit }) {
			commit(CUSTOMER_CLEAR_COMPANY);
		},
		async postCustomerPermission({ commit }, params = {}) {
			try {
				commit(POST_CUSTOMER_PERMISSION_REQUEST);
				const { data } = await postCustomerPermissionAPI(params);
				commit(POST_CUSTOMER_PERMISSION_SUCCESS, data);
			} catch (e) {
				commit(POST_CUSTOMER_PERMISSION_FAILURE);
			}
		}
	}
};
