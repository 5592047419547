import azzureHttp from "./azureHttp";
import httpClient from "./httpClient";

const qs = require("qs");

const TOKEN_ENDPOINT = "/token";
const USER_STAFF = "/staffs/sessions";
const STAFF_ENDPOINT = "/staffs";
const CUSTOMER_LOGIN_ENDPOINT = "/login";
const CUSTOMER_CHANGE_PASSWORD_ENDPOINT = "/me/password";
const getQueryString = (code, codeVerifier) => qs.stringify({
	client_id: process.env.VUE_APP_AZURE_CLIENT_ID,
	scope: "user.read",
	code,
	grant_type: "authorization_code",
	code_verifier: codeVerifier,
	redirect_uri: process.env.VUE_APP_AZURE_LOGIN_CALLBACK
});

const getAzureAccessTokenAPI = (code, codeVerifier) => azzureHttp.post(`${TOKEN_ENDPOINT}`, getQueryString(code, codeVerifier));

const getStaffSessionAPI = (accessToken) => httpClient.post(`${USER_STAFF}`, { accessToken });

const getUserInfoAPI = () => httpClient.get("/me");

const getUserModulesAPI = () => httpClient.get("/me/modules");

const getAzureUserList = (params) => httpClient.get(`${STAFF_ENDPOINT}/addable`, { params });

const postStaff = (params) => httpClient.post(`${STAFF_ENDPOINT}`, params);

const getUserLogoutAPI = () => httpClient.get("/logout");

const getCheckTokenExpire = () => httpClient.get("/sessions");

const postCustomerLogin = (params) => httpClient.post(`${CUSTOMER_LOGIN_ENDPOINT}`, params);

const postCustomerChangePasswordAPI = (params) => httpClient.put(`${CUSTOMER_CHANGE_PASSWORD_ENDPOINT}`, params);

export {
	getAzureAccessTokenAPI,
	getStaffSessionAPI,
	getUserInfoAPI,
	getUserModulesAPI,
	getAzureUserList,
	postStaff,
	getUserLogoutAPI,
	getCheckTokenExpire,
	postCustomerLogin,
	postCustomerChangePasswordAPI
};
