export async function generateCodeChallenge(codeVerifier) {
	const encoder = new TextEncoder();
	const data = encoder.encode(codeVerifier);
	const digest = await window.crypto.subtle.digest("SHA-256", data);
	return btoa(String.fromCharCode(...new Uint8Array(digest)))
		.replace(/\+/g, "-")
		.replace(/\//g, "_")
		.replace(/=+$/, "");
}

export function generateCodeVerifier() {
	const array = new Uint32Array(32);
	window.crypto.getRandomValues(array);
	return Array.from(array, (dec) => (`0${dec.toString(16)}`).substr(-2)).join("");
}

export function generateCodeNonce() {
	return Array.from(window.crypto.getRandomValues(new Uint32Array(1)))[0];
}

