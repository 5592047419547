<template>
	<div :class="['module-item', { active: isActive }]">
		<div class="module-item-header" @click="toggle">
			<div class="module-item-name">
				<h2>{{ item.shipId }}</h2>
				<div>{{` (${item.companyName})` }}</div>
			</div>
			<div class="module-item-code"><span class="module-item-label">Product:</span> {{ item.productName }}</div>
			<div class="module-item-key"><span class="module-item-label">Module:</span> {{ item.module }}</div>
			<div class="module-item-toggle">
				<a>
					{{ isActive ? 'Hide users' : 'Show users' }} <FontAwesomeIcon :icon="['far', 'chevron-circle-down']" />
				</a>
			</div>
		</div>
		<div class="module-item-body container">
			<div class="row container-item">
				<div class="container-item-head">
					<div class="container-item-head-action">
					</div>
				</div>
				<div class="container-item-body">
					<div v-if="table.tbody.length === 0" class="no-result"> There is no customer assigned to this device.</div>
					<BaseTableCustom
						v-else
						:tableOptions="tableOptions"
						:loading="false"
					>
						<template v-slot:thead>
							<tr>
								<th
									v-for="({ value, ...rest }, index) in table.thead"
									:key="index"
									:class="rest.class"
								>
									{{ value }}
								</th>
							</tr>
						</template>
						<template v-slot:tbody>
							<tr
								v-for="(item, index) in table.tbody"
								:key="index"
							>
								<td>
									<router-link
										:to="item.type === 'Customer' ? `/customers/${item.id}` : `/users/${item.id}`"
									>
										{{ item.name }}
									</router-link>
								</td>
								<td>
									{{ item.email }}
								</td>
								<td>
									{{ item.type }}
								</td>
								<td>
									{{ item.isActive ? 'Active' : 'Inactive' }}
								</td>
							</tr>
						</template>
					</BaseTableCustom>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BaseTableCustom from "@/components/BaseTableCustom.vue";
// import { cloneDeep } from "../../selectors/helpers";

export default {
	name: "DeviceListItem",

	components: {
		BaseTableCustom
	},

	props: {
		item: {
			type: Object,
			default: null
		},
		query: {
			type: Object,
			default: () => ({})
		}
	},

	data() {
		return {
			tableOptions: [
				"is-striped is-fullwidth"
			],
			isActive: false,
			status: {
				parent: false
			},
			users: []
		};
	},

	computed: {
		table() {
			return {
				thead: [
					{ value: "Name", key: "", class: "" },
					{ value: "Email", key: "", class: "" },
					{ value: "Account Type", key: "", class: "" },
					{ value: "Status", key: "", class: "" }
				],
				tbody: this?.users || []
			};
		}
	},

	created() {
		this.users = [
			...this.item.customers.map((cus) => {
				return {
					...cus,
					type: "Customer"
				};
			}),
			...this.item.staffs.map((cus) => {
				return {
					...cus,
					name: cus.displayName,
					type: "Staff"
				};
			})
		];
		// eslint-disable-next-line guard-for-in
		for (const userIndex in this.users) {
			const user = this.users[userIndex];
			this.$set(this.status, `child-user-${userIndex}`, user.isVisible);
		}
	},

	methods: {
		async toggle() {
			this.isActive = !this.isActive;
		}
	}
};
</script>

<style lang="scss" scoped>
.module-item {
	border-radius: 4px;
	border: 1px solid $color-silver;
	background-color: $color-grey-1;
	margin-bottom: rem(16);
	line-height: 1.5;
	overflow: hidden;

	// .module-item-name
	&-name {
		flex-basis: rem(380);
		margin-right: rem(30);
		font-size: $font-24;
		line-height: 1.2;
		color: $color-dark-blue-grey;
	}

	// .module-item-label
	&-label {
		font-size: $font-18;
		font-weight: 400;
		color: $color-gunmetal-two;
		margin-right: rem(8);
	}

	// .module-item-code, .module-item-key
	&-code,
	&-key {
		font-weight: 600;
		color: $color-dark-blue-grey;
		margin-right: rem(64);

		@include desktop {
			min-width: rem(150);
			margin-right: rem(10);
		}
	}

	// .module-item-toggle
	&-toggle {
		margin-left: auto;

		a {
			width: rem(120);
			display: inline-block;
			color: $color-cerulean;
			font-weight: 600;

			svg {
				color: $color-cerulean;
				font-size: $font-16;
				margin-left: rem(10);
				position: relative;
				top: rem(3);
			}
		}
	}

	// .module-item-header
	&-header {
		display: flex;
		align-items: center;
		width: 100%;
		padding: rem(8);
		cursor: pointer;
	}

	// .module-item-body
	&-body {
		display: none;
		width: 100%;
		background-color: $color-white;
		padding: 0 rem(16);
	}

	.fa-chevron-circle-down {
		transition: 0.3s transform ease-in-out;
	}

	&.active {
		.module-item-body {
			display: block;
		}

		.fa-chevron-circle-down {
			transform: rotate(180deg);
		}
	}
}

.container-item {
	padding: 0 $spacing-5 $spacing-5 $spacing-5;

	&-head {
		@include fontSub();

		display: flex;
		justify-content: space-between;

		&-status {
			.label {
				color: $color-grey-4;
				font-size: $font-20;
				margin-right: 8px;
			}

			.value {
				color: $color-dark-blue-grey;
				font-size: $font-20;
				font-weight: $font-weight-bold;
			}
		}

		&-action {
			display: flex;
			align-items: center;
			margin-right: rem(14);
		}
	}

	&-body {
		.no-result {
			text-align: center;
		}

		margin-top: rem(14);

		/* stylelint-disable */
		&::v-deep .container-custom-main-table .action {
			text-align: right !important;
		}
		/* stylelint-enable */
	}
}

.margin-button {
	margin-top: rem(10);
}
</style>
